/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link as NavLink } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import kebabCase from "lodash/kebabCase"
import { Box, Button, Typography, Link } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

const ArticleCard = ({ article }) => {
    const { title, categories, lastUpdatedDate, featuredImage, tags } = article.frontmatter;
    const { slug, excerpt, timeToRead } = article;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Link component={NavLink} to={`/${slug}`}>
            {featuredImage && (<GatsbyImage
                image={featuredImage.childImageSharp.gatsbyImageData}
                placeholder="blurred"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt={title}
                style={{ borderRadius: '8px', maxWidth: '100%' }}
            />)}
            </Link>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between' }}>
                <Box>
                    {categories.map(category => (
                        <Link component={NavLink} underline="hover" key={category} to={`/category/${kebabCase(category)}/`} sx={{mr: 1, fontSize: '.8rem'}}>{category}</Link>
                    ))}
                </Box>
                {tags.length > 0 && (
                    <Box>
                        {tags.map(tag => (
                            <Link component={NavLink} underline="hover" key={tag} to={`/tag/${kebabCase(tag)}/`} sx={{ml: {xs: 0, md: 1}, mr: {xs: 1, md: 0}, fontSize: '.8rem'}}>#{tag}</Link>
                        ))}
                    </Box>
                )}

            </Box>
            <Box>
                <Typography variant="subtitle1" sx={{fontSize: '.8rem'}}>{timeToRead} min read &#183; Last updated {lastUpdatedDate}</Typography>
            </Box>
            <Typography gutterBottom variant="h4" component="h2">
                <Link component={NavLink} underline="hover" to={`/${slug}`}>
                    {title}
                </Link>
            </Typography>
            <Typography variant="body1">{excerpt}</Typography>
        </Box>
    )
}

export default ArticleCard
