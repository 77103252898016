/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Button, Grid, Container, Typography } from "@mui/material";

import ArticleList from './article-list'

const FeaturedPosts = () => {
    const posts = useStaticQuery(graphql`
    query FeaturedPostsQuery {
        allMdx(filter: {frontmatter: {featured: {eq: true}, status: {eq: "published"}}}) {
            edges {
              node {
                frontmatter {
                  categories
                  lastUpdatedDate(formatString: "MMM DD, YYYY")
                  tags
                  featuredImage {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  title
                }
                id
                slug
                excerpt
                timeToRead
              }
            }
          }
    }
  `)

    return (
        <Container sx={{ position: 'relative', mt: 5, mb: 15 }}>
            <Grid container spacing={4} sx={{ display: 'flex' }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="h2">Featured Articles</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ArticleList posts={posts} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default FeaturedPosts
