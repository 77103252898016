/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Button, Grid, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import ArticleCard from './article-card'

const ArticleList = ({posts}) => {
    return (
        <Grid container spacing={4} sx={{ display: 'flex' }}>
            {posts.allMdx.edges.map(node => (
                <Grid item xs={12} md={6} key={node.node.id}>
                    <ArticleCard article={node.node} />
                </Grid>
            )
            )}
        </Grid>
    )
}

export default ArticleList
