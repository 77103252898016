import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedPosts from "../components/featured-posts";
import { Container, Typography } from '@mui/material';

const ThankYouPage = () => (
    <Layout>
    <Seo title="Thank you" noindex />
    <Container sx={{ my: 4 }}>
      <Typography component="h1" variant="h2" gutterBottom>Thank you!</Typography>
      <Typography variant="body1">Thank you for your submission! We'll get back to you as quickly as we can. In the meantime, check our featured posts.</Typography>
    <FeaturedPosts />
    </Container>
  </Layout>
)

export default ThankYouPage